<template>
  <Swiper
    :options="options"
  >
    <SwiperSlide
      v-for="(list, groupedIndex) in groupedList"
      :key="groupedIndex"
    >
      <template v-for="(item) in list">
        <!--
          @slot 슬라이드 그룹내 데이터 1개에 대한 UI
            @binding item 데이터
        -->
        <slot name="default" :item="item"></slot>
      </template>
    </SwiperSlide>
    <template v-slot:pagination>
      <div class="swiper-pagination"></div>
    </template>
    <template v-slot:button-next>
      <div class="swiper-button-prev"></div>
    </template>
    <template v-slot:button-prev>
      <div class="swiper-button-next"></div>
    </template>
  </Swiper>
</template>

<script>
import _ from 'lodash';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

/**
 * 그룹 슬라이더 컴포넌트
 * @see https://swiperjs.com/demos/#multi_row_slides_layout
 * @see https://github.com/surmon-china/vue-awesome-swiper
 * @see https://swiperjs.com/
 */
export default {
  name: 'GroupedSlider',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    /**
     * 슬라이드 대상 데이터 리스트
     */
    list: {
      type: Array,
    },
    /**
     * 슬라이드 그룹당 데이터 수
     */
    itemsPerGroup: {
      type: Number,
      default: 1,
    },
    /**
     * 자동 슬라이드 여부
     */
    autoplay: {
      type: Boolean,
      default: true,
    },
    /**
     * 순환 여부
     */
    loop: {
      type: Boolean,
      default: true,
    },
    /**
     *  페이지 네비게이션 여부
     */
    pagination: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    groupedList() {
      return _.chunk(this.list, this.itemsPerGroup);
    },
  },
  data() {
    return {
      /**
       * @see https://swiperjs.com/api/#parameters
       */
      options: {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: this.loop,
        /**
         * @see https://swiperjs.com/api/#autoplay
         */
        autoplay: this.autoplay
          ? {
            disableOnInteraction: false,
          }
          : false,
        /**
         * @see https://swiperjs.com/api/#pagination
         */
        pagination: this.pagination ? {
          el: '.swiper-pagination',
          clickable: true,
        } : false,
        /**
         * @see https://swiperjs.com/api/#navigation
         */
        navigation: {
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        },
      },
    };
  },
};
</script>
<style scoped>
</style>
